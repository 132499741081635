<template>
  <div>
    <table cellpadding="0" cellspacing="0" class="simulator-table">
      <thead>
      <tr>
        <th>Plan</th>
        <th v-for="plan in plans">{{ plan.name }}</th>
      </tr>
      </thead>
      <tbody>
      <tr v-if="plans[0].finance.loan_type === 1 || plans[0].finance.loan_type === 6">
        <td class="font-bold">Enganche</td>
        <td v-for="plan in plans">
          ${{ formatPrice(plan.finance.deposit) }} ({{ plan.finance.deposit_percentage * 100 }}%)
        </td>
      </tr>
      <tr>
        <td class="font-bold">Meses</td>
        <td v-for="plan in plans">{{ plan.finance.term }}</td>
      </tr>
      <tr>
        <td class="font-bold">Tasa anual</td>
        <td v-for="plan in plans">
          {{ plan.finance.annual_interest_percentage * 100|toFixed }}%
        </td>
      </tr>
      <tr class="font-bold green"
          v-if="plans[0].finance.loan_type === 1">
        <td class="font-bold">Pago Entrega*</td>
        <td v-for="plan in plans">
          ${{ formatPrice(plan.finance.delivery_term_period_payment) }}
          <template v-if="plan.finance.delivery_term > 0">
            ({{ plan.finance.delivery_term }} Pagos)
          </template>
        </td>
      </tr>
      <tr class="font-bold green">
        <td class="font-bold">Pago Mensual</td>
        <td v-for="plan in plans">
          ${{ formatPrice(plan.finance.monthly_payment) }} ({{ plan.finance.term - plan.finance.delivery_term }} Pagos)
        </td>
      </tr>
      <tr>
        <td></td>
        <td v-for="plan in plans">
          <vs-button
              color="black"
              icon-pack="feather"
              icon="icon-download-cloud"
              @click="showDetailedSimulator(plan)">
            Tabla Detallada
          </vs-button>
        </td>
      </tr>
      </tbody>
    </table>


    <vs-popup
        fullscreen
        class="header-primary"
        :active.sync="showPlanSimulatorDialog"
        title="SIMULACIÓN DE CRÉDITO"
    >
      <plan-details :name_cot="name_cot" :plan="planSelected"></plan-details>
    </vs-popup>
  </div>
</template>

<script>
import {formatPrice} from '@/filters.js'
import PlanDetails from "@components/simulators/express/plan-details.vue";

export default {
  components: {PlanDetails},
  props: ['plans', 'name_cot'],
  data: () => ({
    showPlanSimulatorDialog: false,
    planSelected: null
  }),
  filters: {
    toFixed(value) {
      return value.toFixed(2)
    }
  },
  methods: {
    formatPrice,
    showDetailedSimulator(plan) {
      this.planSelected = plan;
      this.showPlanSimulatorDialog = true;
    }
  },
}
</script>

<style>
.simulator-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 25px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  overflow: hidden;
}

.simulator-table thead tr th {
  padding: 15px 0 15px 0;
  text-align: center;
}

.simulator-table thead {
  background-color: #28DE18;
  color: #fff;
  font-weight: bold;
}
.simulator-table tbody tr {
  border: 0.5px solid #DCDCDC;
  background: #F9F9F9;
}

.simulator-table tbody tr td {
  padding: 10px 5px;
  text-align: center;
}
</style>