<script>
import SimulatorExpressResume from "../../SimulatorExpressResume.vue";
import {formatPrice} from "../../../filters";

export default {
  name: "PlansResult",
  components: {SimulatorExpressResume},
  props: {
    plans: {
      required: true
    },
    financialProduct: {
      type: Object,
      required: true,
    },
    quoteName: {
      type: String,
      required: false
    }
  },
  methods: {
    formatPrice,
    requestDownloadPdfHandler() {
      this.$emit('onRequestDownloadPdf')
    }
  }
}
</script>

<template>
  <vx-card title="Los resultados de la simulación" v-if="plans.length > 0">
    <div slot="no-body" class="pr-6 pl-6 pb-base mt-10">
      <div style="color: #3B3A3E;" class="max-w-2xl">
        <div>
          <h2>Así se vería tu {{financialProduct.short_name}}</h2>
          <vs-button
              color="black"
              class="float-right"
              icon-pack="feather"
              icon="icon-download-cloud"
              @click="requestDownloadPdfHandler()">
            Descargar
          </vs-button>
        </div>

        <p class="text-md font-bold" style=" margin-top: 15px;">Te compartimos la simulación de tu crédito.</p>
        <p class="text-md font-bold">Escoge el financiamiento que mejor se ajuste a ti.</p>

        <div class="table-item bordered w-64 mt-5 text-md">
          <div>Monto a financiar:</div>
          <div class="font-bold">{{ formatPrice(plans[0].finance.total_cost) }}</div>
        </div>

        <div>
          <simulator-express-resume
              v-if="plans.length > 0"
              :plans="plans"
              :name_cot="quoteName"
          />
        </div>
      </div>
    </div>
  </vx-card>
</template>

<style>
.green {
  background-color: #E0FFDD !important;
  color: #197030;
}

.table-item {
  width: 100%;
  background-color: #F5F5F5;
  padding: 8px 10px;
  border-radius: 5px;
  margin: 5px 0;
  display: flex;
  justify-content: space-between;
}

.table-item.dark {
  background-color: #3A3B3E !important;
  color: white;
}

.table-item.bordered {
  background-color: #fff;
  border: 1px solid #000;
  border-radius: 5px;
}

.table-item.green div:nth-child(2) {
  text-align: right;
  font-weight: bold;
  color: #197030 !important;
}

.table-item div:nth-child(2) {
  text-align: right;
  font-weight: bold;
}
</style>
<style scoped>
h2 {
  font-size: 18px;
}


ul.condition-list {
  list-style: none;
  padding-left: 0;
  font-size: 12px;
}

.subtitle {
  font-size: 12px;
  font-weight: bold;
  margin-top: 10px;
}

</style>
