<script>
import {VMoney} from 'v-money'
import SimulatorExpressResume from "@components/SimulatorExpressResume.vue";
import PlansResult from "./PlansResult.vue";

const maxCostWithoutPercent = 700000;

export default {
  name: "SimulatorForm",
  components: {PlansResult, SimulatorExpressResume},
  directives: {money: VMoney},
  watch: {
    'form.term': function (val) {
      if (this.form.total_cost > 0) {
        this.changeTotalCostHandler();
      }
    },
    totalCostFormatted: function (val) {
      this.form.total_cost = val ? parseFloat(val.replace(/[^0-9.]/g, '')) : null;
    },
    depositAmountFormatted: function (val) {
      this.depositAmount = val ? parseFloat(val.replace(/[^0-9.]/g, '')) : null;
    },
    'form.finance_product_id': async function (val) {
      await this.fetchTerms();
      if (!this.terms.includes(this.form.term)) {
        this.form.term = this.terms[0];
      }

      if (this.financialProductSelected.loan_type_id === 4 || this.financialProductSelected.loan_type_id === 5) {
        this.form.delivery_term = 0;
        this.form.deposit_percentage = 0;
      }
    },
  },
  computed: {
    financialProductSelected() {
      return this.financialProducts.find(fp => fp.id === this.form.finance_product_id);
    },
    depositPercentageOptionsByFinancialProduct() {
      if (this.financialProductSelected === undefined) {
        return this.depositPercentageOptions;
      }

      let min = this.financialProductSelected.financing_set.enganche_rango[0];
      let max = this.financialProductSelected.financing_set.enganche_rango[1];

      return this.depositPercentageOptions
          .filter((d) => {
            return d.value >= min && d.value <= max;
          })
          .filter((d) => {
            if (this.form.total_cost >= this.slas.max_total_cost_amount_to_no_deposit_mxn) {
              return d.value !== 0;
            }

            return true;
          })
    },
    termRangeByFinancialProduct() {
        return this.term;
    }
  },
  data: () => ({
    slas: {
      max_total_cost_amount_to_no_deposit_mxn: maxCostWithoutPercent
    },
    planResponses: [],
    financialProducts: [],
    depositAmount: 0,
    money: {
      decimal: '.',
      thousands: ',',
      prefix: '$',
      suffix: '',
      precision: 2,
      masked: true
    },
    terms: [6, 12, 18, 24, 30, 36, 42, 48, 54, 60],
    depositPercentageType: 1,
    depositPercentageOptions: [
      {value: 0, label: '0%'},
      {value: 0.05, label: '5%'},
      {value: 0.10, label: '10%'},
      {value: 0.15, label: '15%'},
      {value: 0.20, label: '20%'},
      {value: 0.25, label: '25%'},
      {value: 0.30, label: '30%'},
      {value: 0.35, label: '35%'},
      {value: 0.40, label: '40%'},
      {value: 0.45, label: '45%'},
      {value: 0.50, label: '50%'},
      {value: 0.55, label: '55%'},
      {value: 0.60, label: '60%'},
      {value: 0.65, label: '65%'},
      {value: 0.70, label: '70%'},
      {value: 0.75, label: '75%'},
      {value: 0.80, label: '80%'},
      {value: 0.85, label: '85%'},
      {value: 0.90, label: '90%'},
    ],
    depositPercentageTypeOptions: [
      {value: 1, text: "Ingresar un porcentaje"},
      {value: 2, text: "Ingresar un monto en pesos (MXN)"},
    ],
    textDictionary: {
      totalCost: {
        1: 'Monto del proyecto con IVA (MXN)',
        6: 'Monto del vehículo con IVA (MXN)',
        4: 'Monto del crédito',
        5: 'Monto del crédito',
      }
    },
    totalCostFormatted: 0,
    depositAmountFormatted: 0,
    form: {
      name: '',
      finance_product_id: 5,
      term: 12,
      total_cost: null,
      delivery_term: 0,
      deposit_percentage: 0,
    }
  }),
  async mounted() {
    this.$vs.loading();

    await this.fetchFinancialProducts()
    await this.fetchTerms()

    // get url params
    const urlParams = new URLSearchParams(window.location.search);
    const creditType = urlParams.get('credit-type');
    const financialProduct = this.financialProducts.find(fp => fp.id === parseInt(creditType));
    if (creditType !== null && financialProduct !== undefined) {
      this.form.finance_product_id = parseInt(creditType);
    }

    await this.getSLASParams();
    this.$vs.loading.close();
  },
  methods: {
    async fetchTerms() {
      this.$vs.loading();
      if (this.financialProductSelected === undefined) {
        return;
      }
      const {data} = await axios.get(`/api/app/loan/term/${this.financialProductSelected.loan_type_id}`);
      this.terms = data.data;
      this.$vs.loading.close();
    },
    async getSLASParams() {
      const slasObjects = ['max_total_cost_amount_to_no_deposit_mxn'];
      const slasParams = "with[]=" + slasObjects.join("&with[]=");
      const slasRes = await axios.get(`/api/v1/forms/getSlasValues?${slasParams}`);
      this.slas = slasRes.data;
    },
    async fetchDeliveryTerm() {
      this.$vs.loading();
      const {data} = await axios.post('api/delivery-term/by-cost', {
        "total_cost": this.form.total_cost,
        "term": this.form.term
      });
      this.$vs.loading.close();
      return data;
    },
    getPayload() {
      return {
        ...this.form,
        currency: "mxn",
        rate: 0,
        loan_type: 1,
        qualification: null,
        surplus_percentage: 0,
        capital_paid_at_term: 0,
        payment_periodicity: "monthly",
        spread_portfolio: true,
        amortization_type: "variable",
        financed_interest_percentage: 0
      }
    },
    async simulate() {
      this.$vs.loading();
      try {
        const {data} = await axios.post('/api/loan/plans/simulate', this.getPayload());

        this.planResponses = data;
      } catch (e) {
        this.$vs.notify({
          title: 'Error',
          text: 'Ocurrió un error al simular el crédito',
          color: 'danger',
          iconPack: 'feather',
          icon: 'icon-alert-circle'
        });
      }
      finally {
        this.$vs.loading.close();
      }
    },
    closedValue(depositPercentageRaw) {
      return this.depositPercentageOptions.reduce((prev, curr) => {
        return (Math.abs(curr.value - depositPercentageRaw) < Math.abs(prev.value - depositPercentageRaw) ? curr : prev);
      });
    },
    calculatePercentage() {
      const m = parseFloat(this.depositAmount);
      const c = parseFloat(this.form.total_cost);
      const depositPercentageRaw = parseFloat((m / c).toFixed(2));
      this.form.deposit_percentage = this.closedValue(depositPercentageRaw).value;
    },
    async fetchFinancialProducts() {
      const res = await axios.get('/api/loan/financial-products/valid')
      this.financialProducts = res.data;
    },
    async changeTotalCostHandler() {
      if (this.financialProductSelected.loan_type_id === 4 || this.financialProductSelected.loan_type_id === 5) {
        return;
      }

      if (this.form.total_cost >= this.slas.max_total_cost_amount_to_no_deposit_mxn) {
        this.form.deposit_percentage = 0.05;
      }

      if (this.form.term !== '' && this.form.total_cost > 0) {
        let data = await this.fetchDeliveryTerm()
        this.form.delivery_term = data.delivery_term;
      }
    },
    async validate() {
      let res = await this.$validator.validateAll();
      if (!res) {
        this.missingFieldsNotif();
        return;
      }
      await this.simulate();
    },
    async print() {
      this.$vs.loading();
      try {
        const response = await axios.post('/api/loan/plans/pdf', this.getPayload())

        const downloadLink = document.createElement("a");
        downloadLink.href = response.data.pdf_base64;
        downloadLink.download = this.name !== '' ? 'Simulación ' + this.form.name + '.pdf' : 'Simulación.pdf'
        downloadLink.click();
      } finally {
        this.$vs.loading.close();
      }
    },
    hasError(f) {
      return this.errors.has(f);
    },
    errorText(f) {
      return this.errors.first(f);
    },
    isSuccess(f) {
      return (this.fields[f] != null && this.fields[f].required && this.fields[f].valid);
    },
  }
}
</script>

<template>
  <div v-if="financialProducts.length > 0">
    <vx-card title="¡Vamos a simular!" class="mb-6">
      <p class="mb-5 black">
        Dale a tu cliente opciones de crédito para llevar a cabo su proyecto.
      </p>
      <div class="vx-row mb-5 mt-10">
        <div class="vx-col lg:w-1/3 md:w-1/2 sm:w-full w-full mb-5">
          <label for="" class="vs-input--label">Tipo de crédito</label>
          <v-select
              class="w-full vs-custom"
              :class="{'has-error': hasError('financialProduct'), 'is-success': isSuccess('financialProduct')}"
              v-model.lazy="form.finance_product_id"
              :clearable="false"
              name="financialProduct"
              v-validate="'required'"
              label="short_name"
              :reduce="item => item.id"
              :options="financialProducts"
              :danger="hasError('financialProduct')"
              danger-text="Seleccione el tipo de crédito"
              :success="isSuccess('financialProduct')">
          </v-select>
          <v-error v-if="hasError('financialProduct')" :error="errorText('financialProduct')"></v-error>
        </div>
      </div>
      <div v-if="form.finance_product_id !== null">
        <div class="vx-row mb-5">
          <div class="vx-col lg:w-1/3 md:w-1/2 sm:w-full w-full mb-5">
            <vs-input
                :label="textDictionary.totalCost[financialProductSelected.loan_type_id]"
                @blur="changeTotalCostHandler"
                v-model="totalCostFormatted"
                v-money="money"
                v-validate="'required|is_not:$0.00'"
                name="totalCost"
                class="w-full"
                :danger="hasError('totalCost')"
                danger-text="Ingrese una cantidad mayor a cero"
                :success="isSuccess('totalCost')"
            />
          </div>
          <div class="vx-col lg:w-1/3 md:w-1/2 sm:w-full w-full mb-5">
            <label for="" class="vs-input--label">Plazo (meses) *</label>
            <v-select
                class="w-full vs-custom"
                :class="{'has-error': hasError('term'), 'is-success': isSuccess('term')}"
                v-model="form.term"
                :clearable="false"
                name="term"
                placeholder="Seleccione el plazo"
                v-validate="'required'"
                :options="terms"
                :danger="hasError('term')"
                danger-text="Seleccione el plazo (meses)"
                :success="isSuccess('term')">
            </v-select>
            <v-error v-if="hasError('term')" :error="errorText('term')"></v-error>
          </div>
          <div class="vx-col lg:w-1/3 md:w-1/2 sm:w-full w-full mb-5" v-if="financialProductSelected.loan_type_id === 1">
            <label for="" class="vs-input--label">Periodo de entrega</label>
            <vs-input
                v-model="form.delivery_term"
                disabled
                name="delivery_term"
                class="w-full"
                v-validate="'required'"
                :danger="hasError('delivery_term')"
                danger-text="Ingrese un periodo de entrega"
                :success="isSuccess('delivery_term')"
            />
          </div>

          <template v-if="financialProductSelected.loan_type_id === 1 || financialProductSelected.loan_type_id === 6">
            <div class="vx-col lg:w-1/3 md:w-1/2 sm:w-full w-full mb-5">
              <div class="w-full flex">
                <label for="" class="vs-input--label">Enganche</label>
                <vx-tooltip
                    title="Enganche"
                    color="primary"
                    class="tooltip-align"
                    text="Selecciones la forma en que desea ingresar el enganche.">
                  <vs-chip class="question-mark" color="primary">?</vs-chip>
                </vx-tooltip>
              </div>
              <v-select
                  label="text"
                  class="w-full vs-custom"
                  v-model="depositPercentageType"
                  :clearable="false"
                  placeholder="Seleccione una opción"
                  :options="depositPercentageTypeOptions"
                  :reduce="text => text.value">
              </v-select>
            </div>

            <div v-if="depositPercentageType === 1" class="vx-col lg:w-1/3 md:w-1/2 sm:w-full w-full mb-5">
              <label for="" class="vs-input--label">Porcentaje de enganche</label>
              <v-select
                  class="w-full vs-custom"
                  v-model.lazy="form.deposit_percentage"
                  placeholder="Seleccione el porcentaje"
                  :options="depositPercentageOptionsByFinancialProduct"
                  :reduce="option => option.value"
                  :clearable="false"
                  :searchable="false">
                <template v-slot:option="option">
                  <span></span>
                  {{ option.label }}
                </template>
              </v-select>
            </div>
            <div v-else-if="depositPercentageType === 2" class="vx-col lg:w-1/3 md:w-1/2 sm:w-full w-full mb-5">
              <vs-input
                  @change="calculatePercentage"
                  v-model="depositAmountFormatted"
                  label="Monto de enganche"
                  v-money="money"
                  class="w-full" />
            </div>
          </template>
          <div class="vx-col lg:w-1/3 md:w-1/2 sm:w-full w-full mb-5">
            <div style="width:100%; display:flex">
              <label for="" class="vs-input--label">Nombre del solicitante</label>
              <vx-tooltip
                  title="Campo opcional"
                  color="primary"
                  class="tooltip-align"
                  text="Un nombre para personalizar tu tabla.">
                <vs-chip class="question-mark" color="primary">?</vs-chip>
              </vx-tooltip>
            </div>
            <vs-input
                v-model="form.name"
                placeholder="(Opcional)"
                class="w-full"/>
          </div>
        </div>

        <!-- Save Button -->
        <div class="vx-row mt-3">
          <div class="vx-col w-full">
            <vs-button class="ml-auto mt-2" @click="simulate()">Simular</vs-button>
          </div>
        </div>
      </div>
    </vx-card>

    <plans-result
        @onRequestDownloadPdf="print()"
        :financial-product="financialProductSelected"
        :plans="planResponses"
        quote-name="" />
  </div>
</template>
