<script>
import {formatPrice} from '@/filters.js'

export default {
  name: 'PlanDetails',
  props: ['plan', 'name_cot'],
  filters: {
    number_format: function (val) {
      return parseFloat(val).toFixed(2)
    }
  },
  methods: {
    formatPrice,
    async download() {
      let request = {
        finance_product_id: this.plan.finance.financial_product_id,
        currency: this.plan.finance.currency,
        rate: 0,
        loan_type: this.plan.finance.loan_type,
        total_cost: this.plan.finance.total_cost,
        term: this.plan.finance.term,
        delivery_term: this.plan.finance.delivery_term,
        surplus_percentage: this.plan.finance.total_cost_surplus_percentage,
        capital_paid_at_term: this.plan.finance.capital_paid_at_term_percentage,
        payment_periodicity: this.plan.finance.payment_periodicity,
        spread_portfolio: this.plan.finance.spread_portfolio,
        amortization_type: this.plan.finance.amortization_type,
        deposit_percentage: this.plan.finance.deposit_percentage,
        financed_interest_percentage: this.plan.finance.financed_interests_percentage,
      };

      const {data} = await axios.post('/api/loan/finance/pdf', request)
      const downloadLink = document.createElement("a");
      downloadLink.href = data.pdf_base64;
      downloadLink.download = this.name !== '' ? 'Simulación ' + this.name_cot + '.pdf' : 'Simulación.pdf'
      downloadLink.click();
    }
  }
}
</script>

<template>
  <vx-card title="Simulación" v-if="plan !== null" class="w-9/12 mx-auto">

    <div slot="no-body" class="pr-6 pl-6 pb-base">
      <div class="flex justify-between gap-4  items-start">
        <div class="text-subtitle mt-2">
          Ahorros proyectados para fines informativos. Cálculos generados en función
          de cotización, consumo y precios actuales de electricidad. Condiciones de crédito sujetas a aprobación.
        </div>
        <div></div>
        <vs-button
            color="black"
            icon-pack="feather"
            icon="icon-download-cloud"
            class="mb-5 mt-5"
            @click="download()">
          Descargar
        </vs-button>
      </div>

      <template v-if="false">
        <div class="vx-row">
          <div class="vx-col w-full">
            <div class="title">Información de cotización.</div>
          </div>
        </div>

        <div class="vx-row">
          <div class="vx-col w-full">

            <!-- resumen del credito -->
            <table class="table-row full-border">
              <caption></caption>
              <tr class="vertical-top">
                <!-- COL 1-->
                <td class="w-50 p-l">
                  <table class="table-row-spaced">
                    <tbody>
                    <tr class="info-row">
                      <td class="irl bold">Título de simulación</td>
                      <td class="irv">{{ name_cot !== '' ? name_cot : '-' }}</td>
                    </tr>
                    </tbody>
                  </table>
                  <table class="table-row-spaced">
                    <tbody>
                    <tr class="info-row">
                      <td class="irl bold">Costo del sistema</td>
                      <td class="irv">{{ formatPrice(plan.finance.total_cost) }}</td>
                    </tr>
                    </tbody>
                  </table>
                </td>

                <!-- COL 2-->
                <td class="w-50 p-r">
                  <!-- FREE SPACE -->
                </td>
              </tr>
            </table>
            <!-- fin resumen del credito -->
          </div>
        </div>
      </template>
      <div class="flex gap-8">
        <div class="w-6/12">
          <h2>El resumen de tu crédito.</h2>

          <div class="table-item">
            <div>Monto solicitado</div>
            <div>${{ formatPrice(plan.finance.total_cost) }}</div>
          </div>
          <div class="table-item">
            <div>+ Comisiones</div>
            <div>${{ formatPrice(plan.finance.applicant_fee_1 + plan.finance.applicant_fee_2) }}</div>
          </div>
          <div class="table-item dark">
            <div>= Monto a financiar</div>
            <div>${{ formatPrice(plan.finance.financed_amount) }}</div>
          </div>
          <div class="table-item bordered">
            <div>Plazo (Meses)</div>
            <div>{{ plan.finance.term }}</div>
          </div>
          <div class="table-item bordered">
            <div>Tasa Anual</div>
            <div>{{ plan.finance.annual_interest_percentage * 100|number_format }}%</div>
          </div>
        </div>
        <div class="w-6/12">
          <h2>Tus pagos.</h2>

          <div class="table-item" v-if="plan.finance.loan_type === 1 || plan.finance.loan_type === 6">
            <div>+ Enganche ({{ plan.finance.deposit_percentage * 100 }}%)</div>
            <div>${{ formatPrice(plan.finance.deposit) }}</div>
          </div>
          <div class="table-item green" v-if="plan.finance.delivery_term > 0">
            <div>Pago Entrega ({{ plan.finance.delivery_term }} Pagos)</div>
            <div>${{ formatPrice(plan.finance.delivery_term_period_payment) }}</div>
          </div>
          <div class="table-item green">
            <div>Pago Mensual ({{ plan.finance.term - plan.finance.delivery_term }} Pagos)</div>
            <div>${{ formatPrice(plan.finance.monthly_payment) }}</div>
          </div>
        </div>
      </div>

      <div class="vx-row mt-6">
        <div class="w-full">
          <h2>Plan de pagos</h2>
          <!-- Tabla plan de pagos -->
          <table class="simulator-table text-md" aria-describedby="pagos">
            <thead>
            <tr>
              <th>Mes</th>
              <th>Pago</th>
              <th>Saldo Inicial</th>
              <th>Amort. a Capital</th>
              <th>Interés</th>
              <th>Comisión</th>
              <th>IVA</th>
              <th>Saldo Final</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="amortizationItem in plan.amortization" :key="amortizationItem.paymentNumber">
              <td>{{ amortizationItem.number }}</td>
              <td>${{ formatPrice(amortizationItem.payment) }}</td>
              <td>${{ formatPrice(amortizationItem.initial_debt) }}</td>
              <td>${{ formatPrice(amortizationItem.amortization) }}</td>
              <td>${{ formatPrice(amortizationItem.interest) }}</td>
              <td>${{ formatPrice(amortizationItem.client_comission) }}</td>
              <td>${{ formatPrice(amortizationItem.tax + amortizationItem.client_commission_tax) }}</td>
              <td>${{ formatPrice(amortizationItem.final_debt) }}</td>
            </tr>
            </tbody>
          </table>
          <!-- Fin Tabla plan de pagos -->

        </div>
      </div>
    </div>
  </vx-card>
</template>